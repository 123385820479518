const zipButton = () => {
  const button = document.getElementById('zip-button')
  const zip = document.querySelector('input[name="zip"]')
  const address = document.querySelector('input[name="address"]')

  button?.addEventListener('click', () => {
    fetch('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + zip.value)
      .then((res) => res.json())
      .then((data) => {
        const { address1, address2, address3 } = data.results[0]
        address.value = address1 + address2 + address3
      })
  })
}

export default zipButton
