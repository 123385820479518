import cover from './modules/cover'
import globalnav from './modules/globalnav'
import opening from './modules/opening'
import scrollAnimation from './modules/scrollAnimation'
import zipButton from './modules/zipButton'

globalnav()
opening()
scrollAnimation()
cover()
zipButton()
