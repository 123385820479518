const { gsap } = require('gsap')

const opening = () => {
  const opening = document.querySelector('.opening')
  if (opening) {
    const logo = opening.querySelector('.opening__logo svg'),
      mainLogo = logo.querySelectorAll('.main path'),
      rMark = logo.querySelector('.r-mark'),
      bg = document.querySelector('.section-hero .content img')

    if (!sessionStorage.getItem('visit')) {
      gsap
        .timeline()
        .to(mainLogo, {
          strokeDashoffset: 0,
          duration: 0.4,
          stagger: 0.1,
          delay: 0.5,
          ease: 'linear',
        })
        .to(
          rMark,
          {
            strokeDashoffset: 0,
            duration: 0.4,
            ease: 'linear',
          },
          '<0.9'
        )
        .to(
          [...mainLogo, rMark],
          {
            strokeOpacity: 0,
            fillOpacity: 1,
          },
          '=+0.3'
        )
        .to(
          opening,
          {
            autoAlpha: 0,
            duration: 1,
          },
          '=+0.7'
        )
        .from(
          bg,
          {
            scale: 1.1,
            duration: 1.5,
            onComplate: () => setVisit(),
          },
          '<'
        )
    } else {
      gsap
        .timeline()
        .set(opening, {
          autoAlpha: 0,
        })
        .from(
          bg,
          {
            autoAlpha: 0,
            scale: 1.1,
            duration: 1.5,
            onComplate: () => setVisit(),
          },
          '<'
        )
    }
  }
}

const setVisit = () => {
  sessionStorage.setItem('visit', true)
}

export default opening
