const globalnav = () => {
  const btn = document.getElementById('js-hamburger-btn'),
    gnav = document.getElementById('js-globalnav'),
    body = document.getElementsByTagName('body')[0]

  const open = () => {
    btn.classList.add('is-open')
    gnav.classList.add('is-show')
    body.classList.add('is-fixed')
  }

  const close = () => {
    btn.classList.remove('is-open')
    gnav.classList.remove('is-show')
    body.classList.remove('is-fixed')
  }

  btn.addEventListener('click', () => {
    btn.classList.contains('is-open') ? close() : open()
  })
}

export default globalnav
