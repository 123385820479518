import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const scrollAnimation = () => {
  const fadein = document.querySelectorAll('.js-fadein')
  fadein.forEach((el) => {
    gsap.from(el, {
      autoAlpha: 0,
      y: 50,
      duration: 0.6,
      scrollTrigger: {
        trigger: el,
        start: 'top 75%',
      },
    })
  })

  const img = document.querySelectorAll('.js-fadein-img')
  img.forEach((el) => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: el,
          start: 'top 75%',
        },
      })
      .fromTo(
        el,
        {
          clipPath: 'inset(100% 0 0 0 )',
        },
        {
          clipPath: 'inset(0% 0 0 0)',
          duration: 0.8,
          ease: 'cubic.out',
        }
      )
      .from(
        el.querySelector(':scope > img'),
        {
          scale: 1.2,
          duration: 1.4,
        },
        '<'
      )
  })
}

export default scrollAnimation
