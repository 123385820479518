import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const cover = () => {
  const targets = document.querySelectorAll('.cover')

  targets.forEach((cover) => {
    const title = cover.querySelector('.cover__title'),
      img = cover.querySelector('.cover__img')

    gsap
      .timeline({
        scrollTrigger: {
          trigger: cover,
          start: 'top 75%',
        },
      })
      .to(title, {
        opacity: 1,
        duration: 0.8,
      })
      .from(
        title,
        {
          y: 30,
          duration: 0.8,
        },
        '<'
      )
      .to(
        img.querySelector('img'),
        {
          scale: 1,
          opacity: 1,
          duration: 1.2,
        },
        '<0.3'
      )
  })
}

export default cover
